
import lisaContentAdapter from '@/slices/LisaContent/adapter';
import lisa from '~/mixins/lisa';

export default {
  name: 'Hero',
  mixins: [lisa],
  props: {
    slice: {
      type: Object,
      required: true,
    },
    fetchSliceData: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    this.sliceData = this.fetchSliceData;
    this.setLisaData();
    const lisa = await lisaContentAdapter(this.slice, {
      prismic: this.$prismic,
      i18n: this.$i18n,
      lisaData: this.lisaData,
      useLisaTitle: true,
    });

    this.heroLisaData = {
      formData: lisa.formLisaData.formData,
      title: lisa.title,
    };
    this.sliceData.formLisaData = this.heroLisaData;
  },
};
