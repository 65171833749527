
import { getSliceComponentProps } from '@prismicio/vue/components';
import { LISA_CTA } from '@/slices/LisaContent/adapter.new';
import heroAdapter from '@/slices/Hero/adapter';
import lisaModuleMixin from '~/mixins/lisaModule';
import translations from '@/mixins/componentTranslations.js';

export default {
  name: 'Hero',
  mixins: [translations, lisaModuleMixin],
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      isNewLisa: null,
    };
  },
  async fetch() {
    const urlParams = this.$route.query;
    const data = await heroAdapter(this.slice, {
      pageData: this.context,
      i18n: this.$i18n,
      enhancedLinkSerializer: this.$enhancedLinkSerializer,
      prismic: this.$prismic,
    });
    this.isNewLisa = data?.isNewLisa;
    this.sliceData = data;

    if (this.isNewLisa) {
      await this.initLisaModule({
        useLisaTitle: true,
        resetButton: false,
        type: 'hero',
        context: this.context,
        slice: this.slice,
        cta: this.sliceData.lisaCTA,
        defaultValues: {
          language: urlParams?.lang,
          destination: urlParams?.dest,
          coverType: urlParams?.type,
          location: urlParams?.loc,
        },
        autoFillWithContext: this.sliceData.lisaContextAutofill,
      });
    }
  },
  methods: {
    onLisaSubmit(event) {
      return this.sliceData.lisaCTA === LISA_CTA.QUOTE
        ? this.onLisaModuleQuote(event)
        : this.onLisaModuleSubmit(event);
    },
  },
};
